import { environment } from "src/environments/environment";
export class coreUrlList {
  comm = environment.commonUrl;
  onDemand = environment.ondemandUrl;
  clientBaseUrl = "";
  // File Upload
  uploadFile: string = `${this.comm}/upload/upload`;
  //  App Setting
  appSetting: string = `${this.comm}/admin/appSetting`;
  getAppSetting: string = `${this.comm}/admin/getAppSetting`;
  greatSettings = `${this.comm}/admin/greatSetting`;
  webSetting: string = `${this.comm}/admin/webSetting`;
  getWebSetting: string = `${this.comm}/admin/getWebSetting`;
  getpostwebLayout: string = `${this.comm}/admin/webLayout`;


  // Auth
  login: string = `${this.comm}/admin/login`;
  logout: string = `${this.comm}/admin/logout`;
  forgotPasswordSendEmail1: string = `${this.comm}/admin/forgotPassword`;
  checkToken: string = `${this.comm}/admin/panel/checkToken`;
  resetPassword: string = `${this.comm}/admin/panel/resetpass`;
  signup = `${this.clientBaseUrl}/v1/client/createClient`;
  setUpBussiness = `${this.clientBaseUrl}/v1/client/updateClient`;
  setUpStore = `${this.clientBaseUrl}/v1/client/updateClient`;
  getModules = `${this.clientBaseUrl}/v1/admin/getModule`;
  loginClient = `${this.clientBaseUrl}/v1/client/loginClient`;
  getClientDetailsByAppId = `${this.clientBaseUrl}/v1/client/getClientDetailsByAppId`;
  checkForgotPassword: string = `${this.comm}/admin/checkForgotPassword`;
  resetpassword: string = `${this.comm}/admin/resetpassword`;
  storeResetPassword: string = `${this.comm}/store/setPassword`;
  getIp: string = `https://api.ipify.org?format=json`;


  // Payment SMS Methods
  getPaymentMethods: string = `${this.comm}/admin/getPaymentMethods`;
  getPaymentMode: string = `${this.comm}/admin/getPaymentModes`;
  getSmsMethods: string = `${this.comm}/admin/getSmsMethods`;
  getEmailMethods: string = `${this.comm}/admin/getEmailMethods`;
  getCallMaskingMethods: string = `${this.comm}/admin/getCallMaskingMethods`;
  managePaymentMethods: string = `${this.comm}/admin/managePaymentMethods`;
  manageSmsMethods: string = `${this.comm}/admin/manageSmsMethods`;
  managefeatureoff: string = `${this.comm}/admin/manageFeatureOff`;
  manageEmailMethods: string = `${this.comm}/admin/manageEmailMethods`;
  manageCallMaskingMethods: string = `${this.comm}/admin/manageCallMaskingMethods`;




  // Admin
  getAdminProfile: string = `${this.comm}/admin/getAdminProfile`;
  updateProfile: string = `${this.comm}/admin/updateProfile`;
  changePassword: string = `${this.comm}/admin/adminChangePass`;
  getStatusList: string = `${this.comm}/admin/getConstants`;


  // User
  user: string = `${this.comm}/admin/user`;
  getUserAllOrders: string = `${this.onDemand}/admin/getAllOrdersCSV`;
  redeemUser: string = `${this.onDemand}/store/getredeemoptions`;
  getAllUsersCSV: string = `${this.onDemand}/admin/getAllUsersCSV`;
  userDocument: string = `${this.comm}/admin/userDocument`;
  getUserOrderCount: string = `${this.onDemand}/admin/getUserOrderCount`;
  updateUserStatus: string = `${this.comm}/admin/blockUnblockUser`;
  getUserById: string = `${this.comm}/admin/user`;
  bulkUploadUser: string = `${this.comm}/admin/bulkUploadUser`;
  getAllUserExport: string = `${this.comm}/admin/getAllUserExport`;
  userVehicleList: string = `${this.onDemand}/admin/getUserVehicle`;
  updateVehicle: string = `${this.onDemand}/admin/updateUserVehicle`;


  // Dashboard
  dashboard: string = `${this.comm}/admin/dashboard`;
  recentBooking: string = `${this.onDemand}/admin/recentBooking`;
  mainDashboard: string = `${this.comm}/admin/getDashboardMainStats`;
  HeatMapDashBoard: string = `${this.comm}/admin/getDashboardHeatMap`;
  recentBookingDashboard: string = `${this.comm}/admin/getDashboardRecentBooking`;
  cashBookingDashboard: string = `${this.comm}/admin/getDashboardCashBooking`;
  onlineBookingDashboard: string = `${this.comm}/admin/getDashboardOnlineBooking`;
  categoriesDashboard: string = `${this.comm}/admin/getDashboardcategoryMap`;
  monthlyReportDashboard: string = `${this.comm}/admin/getDashboardMonthlyReport`;

  // Zone
  getZones: string = `${this.onDemand}/zone/getZones`;


  //Geofence
  getGeofence: string = `${this.onDemand}/zone/geofence`;
  addGeofence: string = `${this.onDemand}//zone/addGeofence`;


  // CMS - Faq
  addFaq: string = `${this.comm}/admin/addFaq`;
  editFaq: string = `${this.comm}/admin/editFaq`;
  getFaq: string = `${this.comm}/admin/getAllFaq`;
  rearrangingFaq: string = `${this.comm}/admin/changeArrangingOrderFaq`;


  //  CMS - CRM
  addCrm: string = `${this.comm}/admin/addCrm`;
  getCrm: string = `${this.comm}/admin/getCrm`;


  //Custome Template
  getDataType: string = `${this.comm}/admin/dataType`;
  addTemplate: string = `${this.comm}/admin/templateField`;
  getTemplate: string = `${this.comm}/admin/templateField`;
  updateTemplate: string = `${this.comm}/admin/templateField`;
  deleteTemplate: string = `${this.comm}/admin/templateField`;
  getTemplateById: string = `${this.comm}/admin/templateFieldById`;
  getFormType: string = `${this.comm}/admin/getTemplateType`;


  // Billing
  nextInvoice: string = `${this.onDemand}/admin/upcomingInvoices`;
  getAllInvoices: string = `${this.onDemand}/admin/getAllInvoices`;


  // PromoCodes (Deals and Coupans)
  promoCode: string = `${this.comm}/admin/promocode`;
  deletePromoCode: string = `${this.comm}/admin/deletePromoCode`;


  // Taxi
  getRevenueTaxi: string = `${this.onDemand}/admin/revenue/taxi`;


  // Membership Plans
  addMembershipPlan: string = `${this.onDemand}/admin/addMembershipPlan`;
  addUserMembershipPlan: string = `${this.onDemand}/admin/addUserMembershipPlan`;
  getAllUserMembershipPlan: string = `${this.onDemand}/admin/getAllUserMembershipPlan`;
  deleteMembershipPlan: string = `${this.onDemand}/admin/deleteUserMembershipPlan`;
  getUserMemberShipbyid: string = `${this.onDemand}/admin/getUserMembershipPlanById`;
  updateUserMembership: string = `${this.onDemand}/admin/updateUserMembershipPlan`;


  getAllMembershipPlan: string = `${this.onDemand}/admin/getAllMembershipPlan`;
  getMembershipPlanById: string = `${this.onDemand}/admin/getMembershipPlanById`;
  editMembershipPlan: string = `${this.onDemand}/admin/editMembershipPlan`;


  // Vehical Types
  vehicalTypes: string = `${this.onDemand}/admin/vehicletype`;
  deleteVehicleType: string = `${this.onDemand}/admin/driver/deleteVehicleType`;
  driverVehicalTypes: string = `${this.onDemand}/driver/vehicletypes`;


  // Driver
  driver: string = `${this.onDemand}/admin/driver/detail`;
  addDriver: string = `${this.onDemand}/driver/register`;
  getDriverList: string = `${this.onDemand}/admin/driver/all`;
  getDriverOrderCount: string = `${this.onDemand}/admin/driver/getDriverOrderCount`;
  getDriverRatings: string = `${this.onDemand}/admin/driver/getDriversRatings`;
  updateDriverStatus: string = `${this.onDemand}/admin/driver/blockUnblockDriver`;
  driverDocument: string = `${this.onDemand}/admin/driver/document`;
  verifydocument: string = `${this.onDemand}/admin/driver/verifydocument`;
  driverByStatus: string = `${this.onDemand}/admin/driver/driverByStatus`;
  getAllVerticleTypes: string = `${this.onDemand}/driver/vehicletypes`;
  getAllDriverCSV: string = `${this.onDemand}/admin/getAllDriverCSV`;


  // Driver (using driver panel base url)
  getDriverToken: string = `${this.onDemand}/admin/driverToken`;
  getActiveRiders: string = `${this.onDemand}/wallet/riderList`;


  // Admin Documents
  getDocs: string = `${this.comm}/admin/document`;


  // Comapny
  addCompany: string = `${this.onDemand}/company/addCompany`;
  getCompany: string = `${this.onDemand}/company/getCompany`;
  getCompanyById: string = `${this.onDemand}/company/getCompanyById`;
  updateCompany: string = `${this.onDemand}/company/updateCompany`;
  getStoreInventoryAll: string = `${this.onDemand}/company/updateCompany`;


  // Customer Support
  addCustomerSupport: string = `${this.comm}/user/customerSupport`;
  customerSupport: string = `${this.comm}/admin/customerSupport`;
  customerSupportReason: string = `${this.comm}/admin/customerSupportReason`;


  // Gift Card
  addGift: string = `${this.onDemand}/admin/gift/addGift`;
  getAllGift: string = `${this.onDemand}/admin/gift/getAllGift`;
  updateGift: string = `${this.onDemand}/admin/gift/updateGift`;


  // Language Management --
  addLanguage: string = `${this.onDemand}/admin/addLanguage`;
  getAllLanguage: string = `${this.onDemand}/admin/getAllLanguage`;
  editLanguage: string = `${this.onDemand}/admin/editLanguage`;
  deleteLanguage: string = `${this.onDemand}/admin/deleteLanguage`;


  // Sales Report
  itemWiseSalesPerAreaReport: string = `${this.comm}/admin/itemWiseSalesPerAreaReport`;


  // Broadcast Notification
  broadcastNotification: string = `${this.comm}/admin/broadcast`;
  getAllBroadCast: string = `${this.comm}/admin/getAllBroadCast`;
  getBroadCastById: string = `${this.comm}/admin/getBroadCastById`;


  // Payments
  getAllSalesPersonStores: string = `${this.comm}/admin/store/getAllSalesPersonStores`;
  getDriverTotalCommsion: string = `${this.comm}/admin/driver/getAllDriversTotalCommission`;
  getStoreTotalCommsion: string = `${this.comm}/admin/store/getAllMerchantTotalCommission`;
  getPaymentHistory: string = `${this.comm}/admin/payHistory`;
  payStore: string = `${this.comm}/admin/store/PAY`;
  orderWiseCommission: string = `${this.comm}/admin/store/getMerchantCommissionByOrder`;
  getInvoicesList: string = `${this.onDemand}/admin/store/getStoreInvoices`;


  // Sales Person
  subAdmin: string = `${this.comm}/admin/subAdmin`;
  merchandiser: string = `${this.onDemand}/admin/getAllMerchandiser`;
  verifier: string = `${this.onDemand}/admin/getAllVerifier`;
  merchandiserStatus: string = `${this.onDemand}/admin/changeStatusMerchandiser`;
  verifierStatus: string = `${this.onDemand}/admin/changeStatusVerifier`;
  addSalesPerson: string = `${this.onDemand}/admin/createSalesPerson`;
  deleteSalesPerson: string = `${this.onDemand}/admin/deleteSalesPerson`;
  getsalespersonbyid: string = `${this.onDemand}/admin/store/getsalespersonbyid`;
  updateSalesPerson: string = `${this.onDemand}/admin/store/editsalespersonbyId`;
  getSalesPersonList: string = `${this.onDemand}/admin/store/getAllSalesPerson`;
  getSubAdminById: string = `${this.comm}/admin/subAdminById`;


  // Admin Settings New Modules -- Start
  prefence: string = `${this.onDemand}/admin/prefence`;
  getAllReferal: string = `${this.onDemand}/admin/getAllReferal`;
  manageReferral: string = `${this.onDemand}/admin/manageReferral`;
  document: string = `${this.comm}/admin/document`;
  getSeo: string = `${this.onDemand}/admin/getSEO`;
  manageSeo: string = `${this.onDemand}/admin/manageSeo`;
  getCancellation: string = `${this.onDemand}/admin/getCancellation`;
  manageCancellation: string = `${this.onDemand}/admin/addCancellation`;
  deleteCancellation: string = `${this.onDemand}/admin/deleteCancellation`;
  manageCustomerSetting: string = `${this.onDemand}/admin/customerSettingMang`;
  getCustomerSetting: string = `${this.onDemand}/admin/getCustomerSetting`;
  manageNotification: string = `${this.onDemand}/admin/manageNotification`;
  getNotification: string = `${this.comm}/admin/getNotification`;
  countryJsonThirdParty: `https://openexchangerates.org/api/currencies.json`;
  subscribeToNotification: string = `${this.onDemand}/store/subscribe`;
  addEditGetTags: string = `${this.onDemand}/admin/tags`;
  getTagById: string = `${this.onDemand}/admin/tagsById`;
  tagSlot: string = `${this.onDemand}/admin/tagSlot`;
  createConnection: string = `${this.comm}/admin/createConnection`;
  getAddPutSlot: string = `${this.onDemand}/admin/deliverySlots`;
  deliverySlotsById: string = `${this.onDemand}/admin/deliverySlotsById`;
  addEditTax: string = `${this.onDemand}/admin/tax`;


  //ThirdParty
  // getTimeZone:`https://worldtimeapi.org/api/timezone`;


  // Assign Order
  AssignOrder: string = `${this.onDemand}/admin/freeDriverList`;
  assignDriverManually: string = `${this.onDemand}/admin/assignDriverManually`;


  // Warehouse
  warehouse: string = `${this.onDemand}/admin/warehouse`;
  getAllWarehouse: string = `${this.onDemand}/admin/getAllWarehouse`;
  assignOrderToWareHouse: string = `${this.onDemand}/admin/assignOrdersToWarehouse`;


  // Card releted Api
  addCard: string = `${this.onDemand}/admin/addCard`;
  getCard: string = `${this.onDemand}/admin/getCard`;
  deleteCard: string = `${this.onDemand}/admin/deleteCard`;


  // plan
  getAllPlans: string = `${this.comm}/admin/getAllPlans?`;
  subscribe: string = `${this.comm}/admin/subscribe`;
  unsubscribe: string = `${this.comm}/admin/cancelSubscribe`;


  //wallet
  getSetWallet: string = `${this.onDemand}/wallet/primaryWallet`;
  getTransactionList: string = `${this.onDemand}/wallet/primaryWalletTransaction`;
  getTopupVouchersList: string = `${this.onDemand}/wallet/walletVoucher`;
  addFundsToWallet: string = `${this.onDemand}/wallet/addMoneyByUser`;
  addFundsToWalletByCounter: string = `${this.onDemand}/wallet/addMoneyByCounter`;
  approveVoucherByMerchant: string = `${this.onDemand}/wallet/verifyPayment`;
  addWalletrollback: string = `${this.onDemand}/wallet/walletRollback`;
  generateWalletTopUpReceipt: string = `${this.onDemand}/wallet/generateWalletTopUpReceipt`;

  // Store
  store: string = `${this.onDemand}/admin/store`;

  // Merchandiser
  getMerchandiser: string = `${this.onDemand}/admin/getMerchandiser`;
  updateMerchandiser: string = `${this.onDemand}/admin/updateMerchandiser`;

  //negative held amount
  adjustNegativeHold: string = `${this.onDemand}/wallet/adjustNegativeHold`;

  //reject wallet voucher
  rejectWalletVoucher:string = `${this.onDemand}/wallet/rejectWalletVoucher`

  //exportWalletVoucher
  exportWalletVoucher:string = `${this.onDemand}/wallet/exportWalletVoucher`

  //sendOtpToAdmin
  sendOtpToAdmin:string = `${this.onDemand}/wallet/sendOtpToAdmin`;
  sendOtpToAdminCommon:string = `${this.onDemand}/admin/sendOtpToAdminCommon`;

  //verifyOtpAdmin
  verifyOtpAdmin:string = `${this.onDemand}/wallet/verifyOtpAdmin`;

  //exportPayoutTransactions
  exportPayoutTransactions:string = `${this.onDemand}/wallet/exportPayoutTransactions`;

  //signed URL 
  getSignedURL:string = `${this.onDemand}/admin/getSignedUrl`;

  //upload image
  uploadImage:string = `${this.onDemand}/admin/uploadv3`;

  //getSmsBalance
  getSmsBalance:string = `${this.onDemand}/admin/getSmsBalance`;

}

