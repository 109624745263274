export const environment = {
    production: false,
    credentials: {
      email: "",
      password: "",
    },
    baseUrl: "https://api.dev.zonedeliveryservices.com",
    merchantUrl: "https://merchant.pharmacy.dev.zonedeliveryservices.com",
    commonUrl: "/common/v2",
    ondemandUrl: "/ondemand/v2",
    socketUrl: "https://apiondemand.dev.zonedeliveryservices.com/",
    errorHandlerArr: [],
    apiKey: "AIzaSyCsPriWr1muoozMnLsy5bFQRgLlZ88bZfU",
    superAdminAppId: 'bgfbdsa93upqr1bl36x7dlq7',
    pharmacyAppId: 'lfzyulw7hm4qitee9h13pr50',
    // walletEmail: 'adminpharmacy@zonedeliveryservices.com'
    walletEmail: 'superadmin@zonedeliveryservices.com'
  };
  
